import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMonthlyOptionReport } from '../../interfaces/monthly-option-report.interface';
import { IOptionsCalculateRowPayload } from '../../interfaces/options-calculate-row.interface';

@Component({
    selector: 'lynkd-pattern-options-monthly-table',
    templateUrl: './options-monthly-table.component.html',
    styleUrls: ['./options-monthly-table.component.scss']
})
export class OptionsMonthlyTableComponent {
    @Input()
    public attributeValue: string;

    @Input()
    public timeValue: string;

    @Input()
    public data: IMonthlyOptionReport;

    @Input()
    public loading: boolean;

    @Output()
    public readonly updateTyOptionsApproval: EventEmitter<Array<IOptionsCalculateRowPayload>> = new EventEmitter();

    public async handleMonthlyOptionsSkuEdit(payload: Array<IOptionsCalculateRowPayload>): Promise<void> {
        this.updateTyOptionsApproval.emit(payload);
    }
}
