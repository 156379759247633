<div class='product-view'>
    <ul class='product-navigation'>
        <li
            *ngIf='currentProductIndex > 0'
            class='prev'
            (click)="viewProduct('prev')"
        >
        <img src="assets/icon-chevron-left.svg" width="16" height="auto" class="ml-4" alt="previous product button">
        </li>
        <li
            *ngIf='currentProductIndex < products.length - 1'
            class='next'
            (click)="viewProduct('next')"
        >
            <img src="assets/icon-chevron-right.svg" width="16" height="auto" class="mr-4" alt="next product button">
        </li>
    </ul>

    <div class="row align-items-left align-items-md-center justify-content-between product-title">
        <div *ngIf="products[currentProductIndex]" class="col">
            <h2 mat-dialog-title class="mb-0">
                {{ products[currentProductIndex].description }}
            </h2>
            <!-- <h3 class="font-14 mb-0">{{ product.brand_name }}</h3> -->
        </div>

        <div class="col-3 align-right">
            <button mat-stroked-button color="accent" *ngIf="products[currentProductIndex]" (click)="toggleComments()">
                {{ !commentsShow ? 'Show' : 'Hide' }} comments ({{
                    products[currentProductIndex].comments ? products[currentProductIndex].comments.length : 0
                }})
            </button>
            <button mat-stroked-button color="black" mat-dialog-close>
                Close
                <img src="assets/icon-close-alt.svg" width="14" height="auto" alt="close button">
            </button>
        </div>
    </div>

    <div class="row height-100">
        <div class="col-12 col-md-4">
            <div *ngIf="!commentsShow" class="row height-100">
                <div
                    *ngIf="products[currentProductIndex]"
                    class="col-12 product-images height-75"
                    [class.loading]="imagesLoading"
                >
                    <gallery
                        id="productGallery"
                        [items]="products[currentProductIndex].images"
                        [itemTemplate]="itemTemplate"
                        loadingMode="indeterminate"
                        [thumbWidth]="60"
                        [thumbHeight]="60"
                        thumbPosition="bottom"
                        [nav]="false"
                        [gestures]="false"
                        thumbMode="free"
                    >
                    </gallery>

                    <ng-template #itemTemplate let-index="index" let-type="type" let-data="data" let-currIndex="currIndex">
                        <ng-container *ngIf="!imagesLoading && type === 'imageViewer' && index === currIndex">
                            <ngx-imageviewer [src]="data.src" [width]="imgWidth" [height]="imgHeight"></ngx-imageviewer>
                        </ng-container>
                    </ng-template>

                    <div class='thumbs-nav'>
                        <div class='arrow-prev' (click)='galleryPrev()'>
                            <i aria-label='Previous'
                            >
                            <img src="assets/icon-chevron-left-alt.svg" width="20" height="auto" alt="previous product button">
                            </i>
                        </div>
                        <div class='arrow-next' (click)='galleryNext()'>
                            <i aria-label='Next'
                            ><img src="assets/icon-chevron-right-alt.svg" width="20" height="auto" alt="next product button">
                            </i>
                        </div>
                    </div>

                    <mat-spinner></mat-spinner>
                </div>

                <div *ngIf="!commentsShow" class="col-12 product-attributes height-25" [class.loading]="productLoading">
                    <div class="card">
                        <h3 class="font-14 mb-4">Product attributes</h3>

                        <div class="card-content">
                            <mat-chip-list *ngIf="products[currentProductIndex]">
                                <mat-chip
                                    class="text-capitalize"
                                    disableRipple="true"
                                    *ngFor="let attribute of products[currentProductIndex].meta | keyvalue"
                                    >{{ attribute.key | displayName }}: {{ attribute.value | displayName }}</mat-chip
                                >
                            </mat-chip-list>
                        </div>
                    </div>

                    <mat-spinner></mat-spinner>
                </div>
            </div>


            <div *ngIf='commentsShow' class='row height-100'>
                <div class='col-12 product-comments' [class.loading]='commentsLoading'>
                    <ng-container *ngIf='products[currentProductIndex].comments'>
                        <div class='card comments-card'>
                            <div class='mb-4 clearfix comment-creation-container'>
                                <h3 class='font-14 mb-4'>
                                    Comments ({{
                                        products[currentProductIndex].comments ? products[currentProductIndex].comments.length : 0
                                    }})
                                </h3>
                                <button mat-flat-button (click)='toggleComments()'>
                                    <img src="assets/icon-close.svg" width="14" height="auto" alt="close button">
                                </button>

                                <mat-form-field [floatLabel]="'never'">
                                    <textarea matInput [(ngModel)]="inputs.comment" placeholder="Leave comment here"></textarea>
                                </mat-form-field>

                                <button
                                    mat-stroked-button
                                    color="accent"
                                    class="right"
                                    [disabled]="inputs.comment.length < 1"
                                    (click)="postComment(currentProductIndex)"
                                >
                                    Post
                                </button>
                            </div>

                            <div class="card-content posted-comments-container">
                                <ul *ngIf="products[currentProductIndex].comments.length; else noItems">
                                    <li *ngFor="let comment of products[currentProductIndex].comments.sort(sortComments)">
                                        <div class="left">
                                            <p>
                                                <strong>{{ comment.user_id }}</strong>
                                            </p>
                                        </div>
                                        <div class="right">
                                            <p class="font-12">{{ comment.comment_time }}</p>
                                        </div>
                                        <p>{{ comment.comment_body }}</p>
                                    </li>
                                    <br/>
                                </ul>

                                <ng-template #noItems>
                                    <p>No comments found</p>
                                </ng-template>
                            </div>
                        </div>
                    </ng-container>

                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-8">
            <div class="row height-100">
                <div
                    class="col-12 col-md-6 product-key-metrics height-50"
                    [class.loading]="productKeyMetricsLoading"
                    [class.loading]="productLoading"
                >
                    <div class="card">
                        <h3 class="font-14 mb-4">Key metrics</h3>
                        <div class="key-metrics-all">
                            <mat-checkbox
                                (click)="toggleKeyMetricsAll()"
                                [(ngModel)]="keyMetricsAll"
                                >Show All Time & Location
                            </mat-checkbox>
                        </div>
                        <div class="card-content">
                            <div class="row" *ngIf="products[currentProductIndex]">
                                <div class="col-4" *ngFor="let metric of products[currentProductIndex].productKeyMetrics">
                                    <div>
                                        <p class="text-capitalize">{{ metric.metric_name }}</p>
                                        <h3 class="font-36 primary mb-0">
                                            {{ metric.metric_value }}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <mat-spinner></mat-spinner>
                </div>

                <div
                    class="col-12 col-md-6 product-product-details height-50"
                    [class.loading]="productOrdersLoading"
                    [class.loading]="productLoading"
                >
                    <div class="card">
                        <mat-tab-group>
                            <mat-tab label="Product details">
                                <div class="card-content">
                                    <table class="mat-table" *ngIf="products[currentProductIndex]">
                                        <tr *ngFor="let attr of productStyleColumns; let colIndex = index" class="mat-row">
                                            <td class="mat-cell text-capitalize">
                                                <strong>{{ attr.displayName }}:</strong>
                                                {{ products[currentProductIndex][attr.columnName] | displayName }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </mat-tab>

                            <mat-tab label="Order details">
                                <mat-form-field
                                    *ngIf="products[currentProductIndex]"
                                    class="button-stroked"
                                    [floatLabel]="'never'"
                                >
                                    <mat-label>Order</mat-label>
                                    <mat-select
                                        [(ngModel)]="inputs.order"
                                        (selectionChange)="onProductOrderSelectChange(currentProductIndex)"
                                    >
                                        <mat-option value="">Order</mat-option>
                                        <mat-option
                                            *ngFor="let order of products[currentProductIndex].productOrderData"
                                            [value]="order.order_no"
                                            class="order-details"
                                            [class.delivered]="order.status === 'Fully Received'"
                                        >
                                            {{ order.order_no }} ({{ order.duedate }})
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div *ngIf="products[currentProductIndex]" class="card-content">
                                    <table *ngIf="productOrderDataSelected.length; else noProductOrderItems" class="mat-table">
                                        <tr
                                            *ngFor="
                                                let attr of products[currentProductIndex].productOrderColumns;
                                                let colIndex = index
                                            "
                                            class="mat-row"
                                        >
                                            <td class="mat-cell text-capitalize">
                                                <strong>{{ attr | displayName }}:</strong> {{ productOrderDataSelected[0][attr] }}
                                            </td>
                                        </tr>
                                    </table>

                                    <ng-template #noProductOrderItems>
                                        <p>No order details found</p>
                                    </ng-template>
                                </div>
                            </mat-tab>

                            <mat-spinner></mat-spinner>
                        </mat-tab-group>
                    </div>

                    <mat-spinner></mat-spinner>
                </div>

                <div class="col-12 product-performance-details height-50" [class.loading]="productPerformanceLoading">
                    <div class="card">
                        <div class="row align-items-center">
                            <div class="col">
                                <div class="d-flex flex-row align-content-center align-items-center">
                                    <h3 class="font-14 mb-4">Performance details</h3>
                                    <p *ngIf="isShowingTable" class="font-14 mb-4 ml-3 cursor-pointer" (click)="exportExcel()">
                                        <fa-icon icon="file-alt"></fa-icon>
                                        Export to Excel
                                    </p>
                                </div>
                            </div>
                            <div class="col align-right">
                                <mat-form-field
                                    *ngIf="products[currentProductIndex]"
                                    class="button-stroked mb-4"
                                    [floatLabel]="'never'"
                                >
                                    <mat-label>Detail</mat-label>
                                    <mat-select
                                        [(ngModel)]="inputs.performance_detail"
                                        (selectionChange)="onProductPerformanceItemSelectChange(currentProductIndex)"
                                        (ngModelChange)="onChange()"
                                    >
                                        <mat-option
                                            *ngFor="
                                                let productPerformanceItem of products[currentProductIndex]
                                                    .productPerformanceItems
                                            "
                                            [value]="productPerformanceItem.chart_name"
                                        >
                                            {{ productPerformanceItem.display_name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div [hidden]="!products[currentProductIndex]">
                            <ng-container *ngIf="products[currentProductIndex]">
                                <div
                                    *ngIf="
                                        productPerformanceDataSelected &&
                                        productPerformanceDataSelected[0] &&
                                        productPerformanceDataSelected[0].chart_type === 'TABLE' && products[currentProductIndex].productPerformanceData[
                                            productPerformanceDataSelected[0].sort_order - 1
                                        ].length
                                    "
                                    class="card-content"
                                >
                                    <mat-table
                                        [dataSource]="dataSource"
                                        matSort
                                        (matSortChange)="sortData()"
                                        matSortDirection="asc"
                                        [hidden]="inputs.performance_detail !== productPerformanceDataSelected[0].chart_name"
                                        cdkDropList
                                        cdkDropListOrientation="horizontal"
                                        (cdkDropListDropped)="drop($event)"
                                    >
                                        <ng-container
                                            *ngFor="
                                                let productPerformanceColumn of products[currentProductIndex]
                                                    .productPerformanceColumns
                                            "
                                            [matColumnDef]="productPerformanceColumn"
                                        >
                                            <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                mat-sort-header
                                                class="text-capitalize text-center"
                                                cdkDrag
                                                cdkDragLockAxis="x"
                                            >
                                                {{ productPerformanceColumn | displayName }}
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element[productPerformanceColumn] }}
                                            </td>
                                        </ng-container>

                                        <tr
                                            mat-header-row
                                            *matHeaderRowDef="products[currentProductIndex].productPerformanceColumns"
                                        ></tr>
                                        <tr
                                            mat-row
                                            *matRowDef="let row; columns: products[currentProductIndex].productPerformanceColumns"
                                        ></tr>
                                    </mat-table>
                                </div>
                            </ng-container>

                            <div
                                *ngIf="productPerformanceDataSelected && productPerformanceDataSelected.length > 0"
                                class="chart-container"
                                [hidden]="productPerformanceDataSelected[0].chart_type !== 'CHART'"
                            >
                                <div
                                    class="product-chart"
                                    [hidden]="inputs.performance_detail !== productPerformanceDataSelected[0].chart_name"
                                >
                                    <!--                                    <ng-container *ngIf='!this.productPerformanceLoading && chartData && chartOptions'>-->
                                    <canvas
                                        baseChart
                                        id="performance-chart"
                                        [type]="chartType"
                                        [data]="chartData"
                                        [options]="chartOptions"
                                        [legend]="chartLegend"
                                    >
                                    </canvas>
                                    <!--                                    </ng-container>-->
                                    <!--                  <canvas #lineChart height="100%">{{ chart }}</canvas>-->
                                </div>
                            </div>
                        </div>
                    </div>

                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </div>
    </div>
</div>
