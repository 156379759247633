import { Component, OnInit } from '@angular/core';
import { MapsViews } from '../../../core/models/maps-views.enum';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'lynkd-pattern-maps-views',
    templateUrl: './maps-views.component.html',
    styleUrls: ['./maps-views.component.scss']
})
export class MapsViewsComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/typedef
    public readonly MapsViews = MapsViews;
    public activeView: MapsViews = MapsViews.Dashboard;

    public constructor(
        private readonly _router: Router, 
        private readonly _route: ActivatedRoute
    ) {}

    public async changeView(event: MatButtonToggleChange): Promise<boolean> {
        this.activeView = event.value;
        if (this.activeView === MapsViews.Assort) {
            window.location.href = environment.nextFrontendUrl + '/beta';
        }else {
            return this._router.navigate(['/maps', event.value]);
        }
        return false;
    }

    public ngOnInit(): void {
        this._route.params.subscribe((params: Params) => {
            this.activeView = params.view;
        });
    }
}
