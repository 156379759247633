import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IBudgetCalculateTotalResponse } from '../../../../core/models/budget-calculate-total-response.model';
import { IOptionsApprovalResponseModel } from '../../interfaces/options-approval-response.model';
import { IBudgetResponseModel } from '../../interfaces/budget-response.model';
import { IOptionsAttributeValueResponseModel } from '../../interfaces/options-attribute-value-response.model';

@Component({
    selector: 'lynkd-pattern-totals-table',
    templateUrl: './totals-table.component.html',
    styleUrls: ['./totals-table.component.scss']
})
export class TotalsTableComponent implements OnInit, OnChanges {
    @Input()
    public tYOptionsApprovalData: IOptionsApprovalResponseModel;

    @Input()
    public attributeValue: string;

    @Input()
    public availableAttributes: Array<string>;

    @Input()
    public timeValue: string;

    @Input()
    public set loading(data: boolean) {
        if (data) {
            this._loading = data;
            return;
        }

        this._loading = false;
    }

    public get loading(): boolean {
        return this._loading;
    }

    @Output()
    public readonly state: EventEmitter<{attributeValueId: number; budgetChange: IBudgetCalculateTotalResponse}> = new EventEmitter();

    public data: IBudgetCalculateTotalResponse = {
        core_budget: 0,
        core_percentage: 0,
        fashion_budget: 0,
        fashion_percentage: 0,
        actual_budget: 0,
        actual_percentage: 0,
        total_budget: 0
    };

    public columns: Array<string> = ['total', 'core', 'fashion', 'actual'];
    private _loading: boolean;

    public ngOnInit(): void {
        this.initializeBudgetsTable();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.tYOptionsApprovalData) {
            this.initializeBudgetsTable();
        }
    }

    public async stopEdit(attributeValueData: IOptionsAttributeValueResponseModel): Promise<void> {
        this.loading = true;
        try {
            this.updateState({
                attributeValueId: attributeValueData.id, 
                budgetChange: this.mapOptionToBudgetCalculateTotal(attributeValueData.budget)
            });
            this.loading = false;
        } catch (error) {
            this.loading = false;
        }
    }

    public getColumnValue(col: string, attributeValueData: IOptionsAttributeValueResponseModel, isPercentage?: boolean): number {
        if (col === 'total') {
            return attributeValueData.budget.totalBudget;
        } else if (col === 'core') {
            if (isPercentage) {
                return attributeValueData.budget.corePercentage;
            }else {
                return (attributeValueData.budget.corePercentage/100)*attributeValueData.budget.totalBudget;
            }
        } else if (col === 'fashion') {
            if (isPercentage) {
                return attributeValueData.budget.fashionPercentage;
            }else {
                return (attributeValueData.budget.fashionPercentage/100)*attributeValueData.budget.totalBudget;
            }
        } else if (col === 'actual') {
            return 0;
        }
    }

    public updateState(budgetUpdates: {attributeValueId: number; budgetChange: IBudgetCalculateTotalResponse}): void {
        this.state.emit(budgetUpdates);
    }

    public mapOptionToBudgetCalculateTotal(budget: IBudgetResponseModel): IBudgetCalculateTotalResponse {
        return {
            core_budget: budget.corePercentage && budget.totalBudget ? budget.totalBudget*budget.corePercentage/100 : 0,
            core_percentage: budget.corePercentage,
            fashion_budget: budget.fashionPercentage && budget.totalBudget ? budget.totalBudget*budget.fashionPercentage/100 : 0,
            fashion_percentage: budget.fashionPercentage,
            actual_budget: 0, // TODO: Where to find Actual Budget. Is this to be calculated?
            actual_percentage: 0, // TODO: Where to find Actual Budget. Is this to be calculated?
            total_budget: budget.totalBudget
        };
    }

    public initializeBudgetsTable(): void {
        if (this.tYOptionsApprovalData) {
            for (const attributeValue of (this.attributeValue === 'ALL' ? this.availableAttributes : [this.attributeValue])) {
                // We're working with a single attribute here, so filter out that attribute value data and pass
                // it to the rest of the function
                const tyOptionsAttributeValueData: IOptionsAttributeValueResponseModel = 
                                                    this.tYOptionsApprovalData.attributeValues.find(
                                                        (optionsAttributeValue: IOptionsAttributeValueResponseModel) => 
                                                            optionsAttributeValue.attributeValue === attributeValue
                                                    );
    
                if (tyOptionsAttributeValueData && tyOptionsAttributeValueData.budget) {
                    // this.data = this.mapOptionToBudgetCalculateTotal(tyOptionsAttributeValueData.budget);
                    this.updateState({
                        attributeValueId: tyOptionsAttributeValueData.id, 
                        budgetChange: this.mapOptionToBudgetCalculateTotal(tyOptionsAttributeValueData.budget)
                    });
                }
            }
        }
    }

    public transformNumberToPercentage(value: number): string {
        if (!(value === null || value === undefined)) {
            return `${value}%`;
        } else {
            return '0.0%';
        }
    }

    public transformPercentageToNumber(value: string): number {
        return Number(value.replace('%', ''));
    }

    public transformNumberToBeThousandSeparated(value: number): string {
        if (!value) {
            return '0';
        }
      
        return Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    public transformNumberToRemoveThousandSeparator(value: string): number {
        return parseFloat(value.replace(' ', ''));
    }
}
