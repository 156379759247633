<nz-table [nzData]="tYOptionsApprovalData ? tYOptionsApprovalData.attributeValues : []" [nzShowPagination]="false" [nzBordered]="true" [nzLoading]="loading">
    <thead>
        <tr>
            <th class='table-header' colspan="1"></th>
            <th class='table-header' colspan="5">Target</th>
            <th class='table-header' colspan="3" nzRight>Actual</th>
        </tr>
        <tr>
            <th class='table-header'>Product</th>
            <th class='table-header' [nzAlign]="'center'">Total Value</th>
            <th class='table-header' [nzAlign]="'center'">Core %</th>
            <th class='table-header' [nzAlign]="'center'">Core Value</th>
            <th class='table-header' [nzAlign]="'center'">Fashion %</th>
            <th class='table-header' [nzAlign]="'center'">Fashion Value</th>
            <th class='table-header' [nzAlign]="'center'">Total Value</th>
            <th class='table-header' [nzAlign]="'center'">% of Target</th>
            <th class='table-header' [nzAlign]="'center'">Variance to<br/>Target Value</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="tYOptionsApprovalData">
            <tr *ngFor="let attributeValueData of tYOptionsApprovalData.attributeValues">
                <td class="brand-name-cell">{{ attributeValueData.attributeValue }}</td>
                <td [nzAlign]="'center'" [nzAlign]="'center'" class="editable-cell">
                    <nz-input-number
                        nzBorderless
                        class="input"
                        [(ngModel)]="attributeValueData.budget.totalBudget"
                        (blur)="stopEdit(attributeValueData)"
                        (keydown.enter)="stopEdit(attributeValueData)"
                        [nzFormatter]="transformNumberToBeThousandSeparated"
                        [nzParser]="transformNumberToRemoveThousandSeparator"
                    ></nz-input-number>
                </td>
                <td [nzAlign]="'center'" [nzAlign]="'center'" class="editable-cell">
                    <nz-input-number
                        nzBorderless
                        class="input"
                        [(ngModel)]="attributeValueData.budget.corePercentage"
                        (blur)="stopEdit(attributeValueData)"
                        (keydown.enter)="stopEdit(attributeValueData)"
                        [nzMin]="0"
                        [nzMax]="100"
                        [nzFormatter]="transformNumberToPercentage"
                        [nzParser]="transformPercentageToNumber"
                    ></nz-input-number>
                </td>
                <td [nzAlign]="'center'" class="uneditable-cell">
                    {{ getColumnValue('core', attributeValueData) | thousandSeparator}}
                </td>
                <td [nzAlign]="'center'" class="editable-cell">
                    <nz-input-number
                        nzBorderless
                        class="input"
                        [(ngModel)]="attributeValueData.budget.fashionPercentage"
                        (blur)="stopEdit(attributeValueData)"
                        (keydown.enter)="stopEdit(attributeValueData)"
                        [nzMin]="0"
                        [nzMax]="100"
                        [nzFormatter]="transformNumberToPercentage"
                        [nzParser]="transformPercentageToNumber"
                    ></nz-input-number>
                </td>
                <td [nzAlign]="'center'" class="uneditable-cell">
                    {{ getColumnValue('fashion', attributeValueData) | thousandSeparator}}
                </td>
                <td [nzAlign]="'center'" class="uneditable-cell">
                    {{ getColumnValue('actual', attributeValueData, true) | percentValue}}
                </td>
                <td [nzAlign]="'center'" class="uneditable-cell">
                    {{ getColumnValue('actual', attributeValueData) | percentValue}}
                </td>
                <td [nzAlign]="'center'" class="uneditable-cell">
                    0
                </td>
            </tr>
        </ng-container>
    </tbody>
</nz-table>
