import { Component, OnInit } from '@angular/core';
import { IOptionsRowCalculationResponse } from '../../../../core/models/options-row-calculation-response.model';
import { MapsService } from '../../services/maps.service';
import { ILYTable } from '../../../../core/models/ly-table.model';
import { IPreviousOptionsResponse } from '../../../../core/models/previous-options-response.model';
import { IBudgetOptionView } from '../../../../core/models/budget-options-view.model';
import { IOption as TyBudget, ITYTable, IOption } from '../../../../core/models/ty-table.model';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../core/core.state';
import { selectUserDetails } from '../../../../core/store/store.selectors';
import { IUserRole } from '../../../../core/models/user-roles.model';
import { IBudgetCalculateTotalResponse } from '../../../../core/models/budget-calculate-total-response.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { firstValueFrom } from 'rxjs';
import { IMonthlyOptionReport, IMonthlyOptionRow } from '../../interfaces/monthly-option-report.interface';
import { OptionsApprovalService } from '../../services/options-approval.service';
import { IOptionsApprovalResponseModel } from '../../interfaces/options-approval-response.model';
import { NotificationService } from '../../../../core/core.module';
import { HttpErrorResponse } from '@angular/common/http';
import { IErrorResponse } from '../../../../shared/models/error-response.model';
import { IFieldErrorResponse } from '../../../../shared/models/field-error-response.model';
import { IOptionsAttributeValueResponseModel } from '../../interfaces/options-attribute-value-response.model';
import { IOptionResponseModel } from '../../interfaces/option-response.model';
import { ISubTimeOptionResponseModel } from '../../interfaces/sub-time-option-response.model';
import { MapsCalculationService } from '../../services/maps-calculation.service';
import { flatMap } from 'lodash';
import { IOptionsCalculateRowPayload } from '../../interfaces/options-calculate-row.interface';
import { IBudgetResponseModel } from '../../interfaces/budget-response.model';
import { IOptionsApprovalRequestModel } from '../../interfaces/options-approval-request.model';
import { IOptionRequestModel } from '../../interfaces/option-request.model';
import { ISubTimeOptionActualPercentageRequestModel } from '../../interfaces/sub-time-option-actual-percentage-request.model';

@Component({
    selector: 'lynkd-pattern-plans',
    templateUrl: './plans.component.html',
    styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {
    public selectedView: string = 'budget';

    public lyMockData: ILYTable;
    public tyMockData: ITYTable;

    public data: IBudgetOptionView;
    public availableAttributes: Array<string> = [];
    public availableTimeValues: Array<string>;
    public selectedTimeValue: string;
    public selectedAttribute: string;
    public selectedAttributeView: IBudgetOptionView;
    public activeViewContext: string;
    public optionsTableData: IBudgetCalculateTotalResponse;
    public monthlyOptionsData: IMonthlyOptionReport;
    public tyTableState: Array<IOption>;
    public loading: boolean = false;

    public tYOptionsApprovalData: IOptionsApprovalResponseModel;
    public lYOptionsApprovalData: IOptionsApprovalResponseModel;

    public constructor(
        private readonly _mapsService: MapsService,
        private readonly _store: Store<IAppState>,
        private readonly _snackbarService: MatSnackBar,
        private readonly _optionsApprovalService: OptionsApprovalService,
        private readonly _notificationService: NotificationService,
        private readonly _mapsCalculationService: MapsCalculationService
    ) {}

    public async ngOnInit(): Promise<void> {
        this.loading = true;
        this.availableAttributes = await this._mapsService.getAttributeValues();
        this.availableTimeValues = await this._mapsService.getTimeValues();

        try {
            const allRoles: { email: string; roles: Array<IUserRole> } = await firstValueFrom(
                this._store.select(selectUserDetails)
            );
            const mapsRoles: IUserRole = allRoles?.roles.find((role: IUserRole) => role.module === 'maps');

            if (mapsRoles?.roles.includes('manager')) {
                this.activeViewContext = 'manager';
            } else if (mapsRoles?.roles.includes('planner')) {
                this.activeViewContext = 'planner';
            }

            this.lyMockData = await this._mapsService.getLYMockData();
            this.tyMockData = await this._mapsService.getTYMockData();

            if (this.availableAttributes && this.availableTimeValues) {
                // Set default selected time and attribute values
                this.selectedTimeValue = this.availableTimeValues.length > 1 ? this.availableTimeValues[1] : null;
                this.selectedAttribute = this.availableTimeValues.length > 0 ? this.availableAttributes[0] : null;
                await this.filterChanged();
            }
        } catch (error) {
            this._snackbarService.open('An error occurred', 'close', {
                duration: 1500
            });
        }
        this.loading = false;
    }

    public async setBudgetAndOptionsData(timeValue: string, attributeValue: string): Promise<void> {
        if (this.tYOptionsApprovalData && this.lYOptionsApprovalData) {
            let tyData: Array<TyBudget>;
            let tyTotal: TyBudget;
            const lastYearOptions: Array<IPreviousOptionsResponse> =  [];
            const thisYearOptions: Array<IOptionsRowCalculationResponse> = [];

            // Iterate through all Attribute Values of Last Year and This Year's Options Approval
            // and map all of their options to lastYearOptions and thisYearOptions respectively
            for (let i: number = 0; i < this.tYOptionsApprovalData.attributeValues.length; i++) {
                const tyOptionsAttributeValueData: IOptionsAttributeValueResponseModel = this.tYOptionsApprovalData.attributeValues[i];
                const lyOptionsAttributeValueData: IOptionsAttributeValueResponseModel = this.lYOptionsApprovalData.attributeValues[i];

                if (tyOptionsAttributeValueData && lyOptionsAttributeValueData) {
                    const mappedPrevOptions: Array<IPreviousOptionsResponse> = this.mapOptionToPreviousOptions(
                        lyOptionsAttributeValueData.attributeValue, 
                        lyOptionsAttributeValueData.id,
                        lyOptionsAttributeValueData.options
                    ).filter((option: IPreviousOptionsResponse) => 
                        !['total', 'grand total'].includes(option.product_level_value.toLowerCase())
                    );

                    const mappedCurrentOptions: Array<IOptionsRowCalculationResponse> = this.mapOptionToOptionsRowCalculation(
                        tyOptionsAttributeValueData.attributeValue,
                        tyOptionsAttributeValueData.id,
                        tyOptionsAttributeValueData.options
                    ).filter(
                        (t: IOptionsRowCalculationResponse) => t.product_level_value !== 'Total'
                    );

                    mappedPrevOptions.forEach((option: IPreviousOptionsResponse) => lastYearOptions.push(option));
                    mappedCurrentOptions.forEach((option: IOptionsRowCalculationResponse) => thisYearOptions.push(option));
                }
            }

            // If there are any options in lastYearOptions and thisYearOptions,
            // then use them to set tyData and tyTotal
            if (thisYearOptions.length > 0 && lastYearOptions.length > 0) {
                // Concat thisYearOptions with lastYearOptions that doesn't 
                // exist in thisYearOptions and set those values to 0
                const metricsInList: Array<string> = thisYearOptions.map((t: IOptionsRowCalculationResponse) => t.product_level_value);
                tyData = thisYearOptions.concat(
                    lastYearOptions
                        .filter((t: IPreviousOptionsResponse) => !metricsInList.includes(t.product_level_value))
                        .map((t: IPreviousOptionsResponse) => ({
                            attributeValueId: t.attributeValueId,
                            optionId: t.optionId,
                            attribute_value: t.attribute_value,
                            product_level_value: t.product_level_value,
                            product_level_name: t.product_level_name,
                            sku_plan: 0,
                            op_sob: 0,
                            units_sales: 0,
                            units_sob: 0,
                            value_wsp_total: 0,
                            value_sob: 0,
                            value_budget: 0,
                            value_var: 0
                        }))
                );
                tyTotal = {
                    attributeValueId: null,
                    optionId: null,
                    attribute_value: attributeValue,
                    product_level_value: 'Total',
                    sku_plan: this.getCalculationResponseColumnTotal("sku_plan", thisYearOptions),
                    op_sob: this.getCalculationResponseColumnTotal("op_sob", thisYearOptions),
                    units_sales: this.getCalculationResponseColumnTotal("units_sales", thisYearOptions),
                    units_sob: this.getCalculationResponseColumnTotal("units_sob", thisYearOptions),
                    value_wsp_total: this.getCalculationResponseColumnTotal("value_wsp_total", thisYearOptions),
                    value_sob: this.getCalculationResponseColumnTotal("value_sob", thisYearOptions),
                    value_budget: this.getCalculationResponseColumnTotal("value_budget", thisYearOptions),
                    value_var: this.getCalculationResponseColumnTotal("value_var", thisYearOptions)
                };

            // Otherwise if their is no data for thisYearOptions, map
            // lastYearOptions and set all values to 0
            } else {
                tyData = lastYearOptions.map((t: IPreviousOptionsResponse) => ({
                    attributeValueId: t.attributeValueId,
                    optionId: t.optionId,
                    attribute_value: t.attribute_value,
                    product_level_value: t.product_level_value,
                    sku_plan: 0,
                    op_sob: 0,
                    units_sales: 0,
                    units_sob: 0,
                    value_wsp_total: 0,
                    value_sob: 0,
                    value_budget: 0,
                    value_var: 0
                }));
                tyTotal = {
                    attributeValueId: null,
                    optionId: null,
                    attribute_value: attributeValue,
                    product_level_value: 'Total',
                    sku_plan: 0,
                    op_sob: 0,
                    units_sales: 0,
                    units_sob: 0,
                    value_wsp_total: 0,
                    value_sob: 0,
                    value_budget: 0,
                    value_var: 0
                };
            }
            this.selectedAttributeView = {
                attribute_value: attributeValue,
                ly_table_data: {
                    columnGroups: this.lyMockData.columnGroups,
                    columns: this.lyMockData.columns,
                    data: lastYearOptions,
                    totals: {
                        attribute_value: attributeValue,
                        product_level_value: 'Total',
                        op_sob: this.getPreviousOptionsResponseColumnTotal("op_sob", lastYearOptions),
                        units_sales: this.getPreviousOptionsResponseColumnTotal("units_sales", lastYearOptions),
                        units_sob: this.getPreviousOptionsResponseColumnTotal("units_sob", lastYearOptions),
                        value_wsp_total: this.getPreviousOptionsResponseColumnTotal("value_wsp_total", lastYearOptions),
                        value_sob: this.getPreviousOptionsResponseColumnTotal("value_sob", lastYearOptions),
                        op_sku_count: this.getPreviousOptionsResponseColumnTotal("op_sku_count", lastYearOptions),
                        avg_units: this.getPreviousOptionsResponseColumnTotal("avg_units", lastYearOptions),
                        moq: this.getPreviousOptionsResponseColumnTotal("moq", lastYearOptions),
                        avg_wsp: this.getPreviousOptionsResponseColumnTotal("avg_wsp", lastYearOptions)
                    }
                },
                ty_table_data: {
                    columnGroups: this.tyMockData.columnGroups,
                    columns: this.tyMockData.columns,
                    data: tyData,
                    total: tyTotal
                }
            };
        }
    }

    public getCalculationResponseColumnTotal(columnName: string, rows: Array<IOptionsRowCalculationResponse>): number {
        let total: number = 0;

        for (const row of rows) {
            if (row[columnName]) {
                total += row[columnName];
            }
        }

        return total;
    }

    public getPreviousOptionsResponseColumnTotal(columnName: string, rows: Array<IPreviousOptionsResponse>): number {
        let total: number = 0;

        for (const row of rows) {
            if (row[columnName]) {
                total += row[columnName];
            }
        }

        return total;
    }

    public mapOptionToPreviousOptions(attributeValue: string, attributeValueId: number, options: Array<IOptionResponseModel>): 
                                                                                Array<IPreviousOptionsResponse> {
        return options.map((option: IOptionResponseModel) => ({
            attributeValueId,
            optionId: option.id,
            attribute_value: attributeValue,
            product_level_value: option.productLevelId,
            product_level_name: option.productLevelName,
            op_sku_count: option.optionsCount,
            op_sob: option.optionShareOfBusiness,
            units_sales: option.unitsSales,
            units_sob: option.unitsShareOfBusiness,
            value_wsp_total: option.valueWholesalePriceTotal,
            value_sob: option.valueShareOfBusiness,
            avg_units: option.averageUnits,
            moq: option.minimumOrderQuantity,
            avg_wsp: option.averageWholesalePrice
        }));
    }

    public mapOptionToOptionsRowCalculation(attributeValue: string, attributeValueId: number, options: Array<IOptionResponseModel>)
                                                                                                    : Array<IOptionsRowCalculationResponse> {
        return options.map((option: IOptionResponseModel) => ({
            attributeValueId,
            optionId: option.id,
            attribute_value: attributeValue,
            product_level_value: option.productLevelId,
            product_level_name: option.productLevelName,
            op_sku_count: option.optionsCount,
            op_sob: option.optionShareOfBusiness,
            units_sales: option.unitsSales,
            units_sob: option.unitsShareOfBusiness,
            value_wsp_total: option.valueWholesalePriceTotal,
            value_sob: option.valueShareOfBusiness,
            avg_units: option.averageUnits,
            moq: option.minimumOrderQuantity,
            avg_wsp: option.averageWholesalePrice,
            value_budget: option.valueBudget,
            value_var: option.valueVariance,
            sku_plan: option.optionsCount
        }));
    }

    public mapOptionsToMonthlyOptionReport(attributeValue: string, attributeValueModel: IOptionsAttributeValueResponseModel): IMonthlyOptionReport {
        const options: Array<IOptionResponseModel> = attributeValueModel.options;
        const budget: IBudgetResponseModel = attributeValueModel.budget;

        return {
            columns: options[0].subTimeOptions.map((subTimeOption: ISubTimeOptionResponseModel) => {
                const subTimeOptions: Array<ISubTimeOptionActualPercentageRequestModel> = flatMap(options, (t: IOptionResponseModel) => 
                                                                    t.subTimeOptions.map((mappedSubTimeOption: ISubTimeOptionResponseModel)=> ({
                                                                        ...mappedSubTimeOption, parentOptionCount: t.optionsCount
                                                                    })))
                                                                    .filter((option: ISubTimeOptionResponseModel) => 
                                                                    option.configSubTimeValue.id === subTimeOption.configSubTimeValue.id);

                return {
                    column_name: subTimeOption.configSubTimeValue.displayName,
                    flow_perc: subTimeOption.configSubTimeValue.flowPercentage,
                    actual_perc: this._mapsCalculationService.getSubTimeOptionActualPercentage(subTimeOptions),
                    budget: this._mapsCalculationService.getSubTimeOptionBudget(
                                                            budget.totalBudget * (budget.fashionPercentage / 100), subTimeOptions)
                };
            }),
            rows: options.map((option: IOptionResponseModel) => ({
                attributeValueId: attributeValueModel.id,
                optionId: option.id,
                product_level_value: option.productLevelId,
                product_level_name: option.productLevelName,
                sku_plans: {...option.subTimeOptions.reduce((acc: Record<string, number>, 
                                                            subTimeOption: ISubTimeOptionResponseModel) => {
                                acc[subTimeOption.configSubTimeValue.displayName] = subTimeOption.optionsCount;
                                return acc;
                            }, {} as Record<string, number>), ...{sku_plan: option.optionsCount, Total: 0}}
            })),
            total: 0, // TODO: Is this the sum of the totals of all options?
            attribute_value: attributeValue
        };
    }

    public async setAllMonthlyOptions(attributeValue: string, timeValue: string): Promise<void> {
        if (this.tYOptionsApprovalData) {
            // Reset monthlyOptionsData
            this.monthlyOptionsData = {
                columns: [],
                attribute_value: attributeValue,
                rows: [],
                total: 0
            };

            for (const tyOptionsAttributeValueData of this.tYOptionsApprovalData.attributeValues) {
                if (tyOptionsAttributeValueData) {
                    const mappedOptionsToMonthlyOptionReport: IMonthlyOptionReport = 
                            this.mapOptionsToMonthlyOptionReport(tyOptionsAttributeValueData.attributeValue, tyOptionsAttributeValueData);
                    
                    this.monthlyOptionsData = {
                        columns: mappedOptionsToMonthlyOptionReport.columns, 
                        attribute_value: attributeValue,
                        rows: [
                            ...(this.monthlyOptionsData ? this.monthlyOptionsData.rows : []),
                            ...mappedOptionsToMonthlyOptionReport.rows
                        ],
                        total: 0
                    };
                }
            }

            if (this.monthlyOptionsData && this.monthlyOptionsData.rows) {
                this.monthlyOptionsData.rows = [
                    // Add total to top of row list, and set all sku values to 0
                    {
                        attributeValueId: null,
                        optionId: null,
                        product_level_value: "Total",
                        product_level_name: "Total",
                        sku_plans: Object.keys(this.monthlyOptionsData.rows[0].sku_plans).reduce(
                                    (acc: Record<string, number>, key: string) => {
                                        acc[key] = 0;
                                        return acc;
                                    }, {} as Record<string, number>)
                    }, ...this.monthlyOptionsData.rows
                ];
            }
        }
    }

    public async filterChanged(): Promise<void> {
        if (this.selectedTimeValue && this.selectedAttribute) {
            this.loading = true;
            try {
                await this.loadOptionsApprovalData(this.selectedTimeValue, this.selectedAttribute);
                await this.setBudgetAndOptionsData(this.selectedTimeValue, this.selectedAttribute);
                await this.setAllMonthlyOptions(this.selectedAttribute, this.selectedTimeValue);
                this.loading = false;
            } catch (e) {
                const response: HttpErrorResponse = e as HttpErrorResponse;
                const error: IErrorResponse = response.error as IErrorResponse;
                let message: string;
                if (error.fieldErrors && error.fieldErrors.length > 0) {
                    message = error.fieldErrors.map((t: IFieldErrorResponse) => t.message).join('\n');
                } else {
                    message = error.message;
                }
                this._notificationService.error(message);
            }
        }
    }

    public async loadOptionsApprovalData(timeValue: string, attributeValue: string): Promise<void> {
        this.loading = true;
        try {
            const tYTimeValue: string = timeValue;
            const lYTimeValue: string = await this._mapsService.getPreviousTimeValue(tYTimeValue);
            
            this.lYOptionsApprovalData = await this._optionsApprovalService.getOptionsApproval(
                lYTimeValue,
                attributeValue
            );

            this.tYOptionsApprovalData = await this._optionsApprovalService.getOptionsApproval(
                tYTimeValue,
                attributeValue
            );

            // Mock data:
            // this.tYOptionsApprovalData = await this._optionsApprovalService.getMockTYOptionsApproval(
            //     tYTimeValue,
            //     attributeValue
            // );

            // this.lYOptionsApprovalData = await this._optionsApprovalService.getMockLYOptionsApproval(
            //     lYTimeValue,
            //     attributeValue
            // );
        } catch (e) {
            const response: HttpErrorResponse = e as HttpErrorResponse;
            const error: IErrorResponse = response.error as IErrorResponse;
            let message: string;
            if (error.fieldErrors && error.fieldErrors.length > 0) {
                message = error.fieldErrors.map((t: IFieldErrorResponse) => t.message).join('\n');
            } else {
                message = error.message;
            }
            this._notificationService.error(message);
        }
        this.loading = false;
    }

    public async viewChanged(evt: MatButtonToggleChange): Promise<void> {
        if (evt.value === 'options') {
            if (this.selectedTimeValue && this.selectedAttribute) {
                // this.loading = true;
                // await this.loadOptionsApprovalData(this.selectedTimeValue, this.selectedAttribute);
                // await this.setBudgetAndOptionsData(this.selectedTimeValue, this.selectedAttribute);
                // this.loading = false;
                // return;
            }
        }
    }

    public async handleOptionsTableUpdate(budgetUpdates: {attributeValueId: number; budgetChange: IBudgetCalculateTotalResponse}): Promise<void> {
        // Update the specific budget in the tYOptionsApprovalData
        if (this.tYOptionsApprovalData && this.lYOptionsApprovalData) {
            // We're working with a single attribute here, so filter out that attribute value data and pass
            // it to the rest of the function
            const tyOptionsAttributeValueData: IOptionsAttributeValueResponseModel = this.tYOptionsApprovalData.attributeValues.find(
                (optionsAttributeValue: IOptionsAttributeValueResponseModel) => optionsAttributeValue.id === budgetUpdates.attributeValueId
            );

            if (tyOptionsAttributeValueData) {
                tyOptionsAttributeValueData.budget = {
                    id: tyOptionsAttributeValueData?.id,
                    totalBudget: Number(budgetUpdates.budgetChange.total_budget),
                    fashionPercentage: Number(budgetUpdates.budgetChange.fashion_percentage),
                    corePercentage: Number(budgetUpdates.budgetChange.core_percentage)
                };

                // Trigger Yearly and Monthly Options table recalculate
                this.tYOptionsApprovalData = this._mapsCalculationService.calculateOptions(this.tYOptionsApprovalData, this.lYOptionsApprovalData);
                await this.setBudgetAndOptionsData(this.selectedTimeValue, this.selectedAttribute);
                await this.setAllMonthlyOptions(this.selectedAttribute, this.selectedTimeValue);
            }else {
                this.monthlyOptionsData = null;
                this.selectedAttributeView = null;
            }
        }else {
            this.monthlyOptionsData = null;
            this.selectedAttributeView = null;
        }
    }

    public async approve(): Promise<void> {
        if (!(this.selectedTimeValue && this.selectedAttribute)) {
            this._snackbarService.open('Please select a season and an attribute', 'close');
            return;
        }
        this.loading = true;

        try {
            await this._optionsApprovalService.approveOptionsApproval(this.tYOptionsApprovalData.id);
            this._snackbarService.open('Budget approved', 'close');
        } catch (e) {
            const response: HttpErrorResponse = e as HttpErrorResponse;
            const error: IErrorResponse = response.error as IErrorResponse;
            let message: string;
            if (error.fieldErrors && error.fieldErrors.length > 0) {
                message = error.fieldErrors.map((t: IFieldErrorResponse) => t.message).join('\n');
            } else {
                message = error.message;
            }
            this._notificationService.error(message);
        }

        this.loading = false;
    }

    public async save(): Promise<void> {
        // if (this.selectedAttributeView?.ty_table_data.data.filter((val: TyBudget) => val.sku_plan === 0).length > 0) {
        //     this._snackbarService.open('Please fill all required inputs', 'close');
        //     return;
        // }

        if (!(this.selectedTimeValue && this.selectedAttribute)) {
            this._snackbarService.open('Please select a time value and an attribute value', 'close');
            return;
        }

        this.loading = true;

        try {
            const saveModel: IOptionsApprovalRequestModel = this.mapOptionsApprovalResponseToRequest(this.tYOptionsApprovalData);
            
            await this._optionsApprovalService.saveOptionsApproval(saveModel);

            this._snackbarService.open('Budget saved', 'close');
        } catch (e) {
            const response: HttpErrorResponse = e as HttpErrorResponse;
            const error: IErrorResponse = response.error as IErrorResponse;
            let message: string;
            if (error.fieldErrors && error.fieldErrors.length > 0) {
                message = error.fieldErrors.map((t: IFieldErrorResponse) => t.message).join('\n');
            } else {
                message = error.message;
            }
            this._notificationService.error(message);
        }
        this.loading = false;
    }

    public mapOptionsApprovalResponseToRequest(optionsApprovalResponse: IOptionsApprovalResponseModel): IOptionsApprovalRequestModel {
        return {
            id: optionsApprovalResponse.id,
            timeValue: optionsApprovalResponse.timeValue,
            attributeValues: 
                optionsApprovalResponse.attributeValues
                .filter((attributeValue: IOptionsAttributeValueResponseModel) => 
                                attributeValue.budget && attributeValue.budget.totalBudget > 0
                        )
                .map((attributeValue: IOptionsAttributeValueResponseModel) => ({
                    id: attributeValue.id,
                    attributeValue: attributeValue.attributeValue,
                    budget: {
                        id: attributeValue.budget.id,
                        totalBudget: attributeValue.budget.totalBudget,
                        fashionPercentage: attributeValue.budget.fashionPercentage,
                        corePercentage: attributeValue.budget.corePercentage
                    },
                    options: attributeValue.options.map((option: IOptionResponseModel) => ({
                        id: option.id,
                        optionsCount: option.optionsCount,
                        productLevelId: option.productLevelId,
                        productLevelName: option.productLevelName,
                        subTimeOptions: option.subTimeOptions.map((subTimeOption: ISubTimeOptionResponseModel) => ({
                            id: subTimeOption.id,
                            optionsCount: subTimeOption.optionsCount,
                            configSubTimeValue: {
                                id: subTimeOption.configSubTimeValue.id,
                                displayName: subTimeOption.configSubTimeValue.displayName
                            }
                        }))
                    })).filter((option: IOptionRequestModel) => option.optionsCount !== 0)
                }))
        };
    }

    public async getTableState(state: {allOptions: Array<IOption>, updatedOption: IOption}): Promise<void> {
        this.loading = true;
        this.tyTableState = state.allOptions;

        // 1. Update the TY Options Approval Data with the newly updated option from the
        // monthly options table
        for (const attributeValue of this.tYOptionsApprovalData.attributeValues) {
            if (attributeValue.id === state.updatedOption.attributeValueId) {
                for (const option of attributeValue.options) {
                    if (
                        state.updatedOption.product_level_value === option.productLevelId &&
                        state.updatedOption.optionId === option.id
                    ) {
                        option.optionsCount = state.updatedOption.sku_plan;
                        break;
                    }
                }
            }
        }

        // 2. Call calculate options method to recalculate the values in the TY table
        this.tYOptionsApprovalData = this._mapsCalculationService.calculateOptions(this.tYOptionsApprovalData, this.lYOptionsApprovalData);
        
        // 3. Use the new Option Approval data to map the Budget, Options, and Monthly Options
        await this.setBudgetAndOptionsData(this.selectedTimeValue, this.selectedAttribute);
        await this.setAllMonthlyOptions(this.selectedAttribute, this.selectedTimeValue);
        await this.updateSingleMonthlyOption(state.updatedOption);

        this.loading = false;
    }

    public async updateSingleMonthlyOption(updatedOption: IOption): Promise<void> {
        this.monthlyOptionsData.rows = this.monthlyOptionsData.rows.map((option: IMonthlyOptionRow) => {
            if (
                option.product_level_value === updatedOption.product_level_value &&
                option.optionId === updatedOption.optionId
            ) {
                option.sku_plans.sku_plan = updatedOption.sku_plan;
                option.sku_plans.Total = updatedOption.sku_plan;
            }
            return option;
        });
    }

    public async handleMonthlyOptionsSkuUpdate(payload: Array<IOptionsCalculateRowPayload>): Promise<void> {
        this.loading = true;

        try {
            // Update the sub time options of each row to reflect the skus changes in the
            // monthly options table for that specific row
            for (const attributeValue of this.tYOptionsApprovalData.attributeValues) {
                for (const updatedOption of payload) {
                    if (
                        attributeValue.id === updatedOption.attributeValueId
                    ) {
                        for (const option of attributeValue.options) {
                            if (
                                updatedOption.product_level_value === option.productLevelId &&
                                updatedOption.optionId === option.id
                            ) {
                                for (const subTimeOption of option.subTimeOptions) {
                                    if (subTimeOption.configSubTimeValue.displayName === updatedOption.sub_time_value) {
                                        subTimeOption.optionsCount = updatedOption.sku_plan;
                                    }
                                } 
                            }
                        }
                    }
                }
            }
        } catch (e) {
            const response: HttpErrorResponse = e as HttpErrorResponse;
            const error: IErrorResponse = response.error as IErrorResponse;
            let message: string;
            if (error.fieldErrors && error.fieldErrors.length > 0) {
                message = error.fieldErrors.map((t: IFieldErrorResponse) => t.message).join('\n');
            } else {
                message = error.message;
            }
            this._notificationService.error(message);
        }
        this.loading = false;
    }
}
